@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

#skills {
    @include section;
    min-height: 90vh;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        text-align: center;
    }

    h3 {
        color: $lightBlue;
        min-width: 100px;
    }

    .list {
      margin-bottom: 50px;

      @media (min-width: $medium-breakpoint) {
        display:flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: baseline;

        div {
            margin-right: 50px;
            flex-basis: 60%;
            font-size: 2rem;
    
            span {
              padding: 0 5px;
            }
          }
      }

    
    }
    
    &:before {
        @include section-line;
    }
    &:after {
        @include section-color;
        right: 0;
        background: $pink;
    }
}
