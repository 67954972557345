@import '_colors.scss';

$max-width: 1000px;
$container-padding: 10%;
$pink: #d91ac2;
$black: #020205;
$red: #7b0533;
$lightBlue: #4676d8;
$darkBlue: #041963;
$gray: #ccc;

$opaqueBlack: rgba(2,2,5,.95);
$opaquePink: rgba(217, 26, 194, .9);
$opaqueLightBlue: rgba(70,118,216, .9);
$opaqueDarkBlue: rgba(4,25,99, .8);
$opaqueRed: rgba(123, 5, 51, .9);


$smallest-breakpoint: 600px;
$medium-breakpoint: 800px;
