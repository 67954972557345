@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

#work {
    @include section;
    padding-top: 3%;

    &:before {
        @include section-line;
    }
    &:after {
        @include section-color;
        left: 0;
        background: $red;
    }

    .work-container {
        width: 90%;
        padding: 5%;
        margin: auto;
    }

    .work-section {
        display:flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .work-item {
        box-sizing: border-box;
        width: 25%;
        position: relative;
        padding: 10px;

        img {
            height: 100%;
        }

        .hover-container {
            position: relative;
        }

        .overlay {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 1.2rem;
            font-weight: bold;
            opacity: 0;
            transition: all .4s ease;

            a {
                color: white;
                text-decoration: none;
                font-size: 2rem;

                &:hover {
                    color: $gray;
                }
            }

            &:hover {
                background: $opaqueRed;
                opacity: 1;
                transition: all .4s ease;

            }   
        }

        @media (max-width: 800px) {
            width: 50%;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
        img { 
            max-width: 100%;
            width: 100%;
        }
        
    }
}
