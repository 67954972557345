@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

#education {
    @include section;
    background: $opaqueBlack;
    padding: 10% 0;
 
    &:before {
        @include section-line;
    }
    &:after {
        @include section-color;
        right: 0;
        background: $darkBlue;
    }

    .intro-paragraph {
        max-width: 63%;
        margin: 0 auto 5%;
    }

    .education-single {
        padding: 2%;
        min-height: 50px;
        position:relative;
        width: 80%;
        margin: auto;
  
        .year {
            font-size: 2.5rem;
            color: $darkBlue;
            padding-bottom: 2.5rem;
        }

        @media (min-width: 600px) {

            .edu-item {
                padding-left: 50px;
                max-width: 80%;
            }

            .year {
                font-size: 1.5rem;
                line-height: 50px;
                border-radius: 50%;
                width: 50px;
                border: 2px solid #929292;
                background: $darkBlue;
                color: white;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -25px;
                z-index: 5; 
                padding-bottom: 0;
            }

            &:before {
                content: '';
                position: absolute;
                width: 2px;
                top: 0;
                bottom: 50%;
                left: 25px;
                background: #929292;
                display: block;
                z-index: 2;
            }
    
            &:after {
                content: '';
                position: absolute;
                width: 2px;
                top: 50%;
                bottom: 0;
                left: 25px;
                background: #929292;
                display: block;
                z-index: 2;
            }

            &:first-child {
                &:before {
                    background: none;
                    height: 0;
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
    
            &:first-of-type {
                &:before {
                    display: none;
                }
            }
    
        }
        
    }
}