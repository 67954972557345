@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.header {
	@include section;
	background: none;
	position: relative;
	z-index: 6;
	padding-top: 70px;

	a {
		margin-left: 50px;
		color: white;
		text-decoration: none;

		&:hover {
			background: -webkit-linear-gradient(#eee, #333);
  			-webkit-background-clip: text;
 			-webkit-text-fill-color: transparent;
		}
	}

	nav {
		position: fixed;
		right: 0;
		width: 100%;
		top: 0;
		background: $opaqueBlack;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		padding: 20px 50px 20px 20px;

		&.closed a {
			@media (max-width: 600px) {
				display: none;
			}	
		}

	@media (max-width: 600px) {
		flex-direction: column-reverse;
		align-content: flex-end;
		align-items: flex-end;

		a {
			padding-top: 25px;
		}
	
	}

		h1 {
			font-size: 4em;
			font-weight: 700;
			margin-bottom: 0;
		}
	}

	.hamburger {
		width: 30px;
		height: 30px;
		display: none;

		@media (max-width: 600px) {
			display:block
		}

		span {
			width: 30px;
			height: 3px;
			background: white;
			display: block;
			position: relative;
			margin-top: 13px;
			transition: all .3s ease-in-out;


			&:before {
				content: '';
				display: block;
				width: 30px;
				height: 3px;
				background: white;
				position: absolute;
				top: -10px;
			}

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 3px;
				background: white;
				position:absolute;
				top: 10px;
				transition: all .3s ease-in-out;
			}

			&.open {
				height: 0;

				&:before {
					transform: rotate(50deg);
					top:0;
					transition: all .3s ease-in-out;

				}
				&:after {
					transform: rotate(-50deg);
					top:0;
				}
			}
		}
	}
}
