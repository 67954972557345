@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

#about {
    @include section;
    padding-top: 10px;
    background-color: $opaqueBlack;
    display:flex;
    justify-content: center;
    align-items: center;

    &:before {
        @include section-line;
    }
    &:after {
        @include section-color;
        left: 0;
        background: $lightBlue;
    }

    h1 {
        font-size: 3em;
        align-self: flex-start;
    }

    img {
        border-radius: 50%;
        max-width: 185px;
    }

    .content {
        width: 70%;
        margin: auto;
    }
}